export const refillWalletValidationConfig = {
  currency: {
    required: true
  },
  credits_to_buy: {
    required: true,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (value && Number(value) < 1000) {
        result.message = 'Credits must be at least 1000.';
      } else result.is_valid = true;
      return result;
    }
  }
};
